import React from "react";
import Header from "./Header";
import HeaderLower from "./HeaderLower";
import Footer2 from "./Footer2";
import './UnderContruction.css'
import Contruction from '../images/2594506.png';

export default function UnderContruction() {
  return (
    <>
      <div className="container-fluid">
        {/* <PreLoader/> */}
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
          {/* <h1 className="under-Contruction">Website</h1>
          <p className="Contruction-p">Under Contruction</p> */}
          <div className="image">
        <img src={Contruction} alt="2594506.png" className="Contruction-img" />
          </div>
          </div>

        </div>
      </div>

      <Footer2 />
    </>
  );
}
