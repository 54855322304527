import React from "react";
import AshleyLogo1 from '../images/ashley-logo1.png';
import LanguageTranslate from "./LanguageTranslate";
function Header() {
  return (
    <div className="row">
      <div className="col-md-12">
      <div className="translator-holder">
        <div className="logo">
          <img
            src={AshleyLogo1}
            alt="ashley-logo.png"
            className="img-responsive"
          />
        </div>
        <LanguageTranslate/>
        </div>
      </div>
    </div>
  );
}
export default Header;
