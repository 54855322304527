import React from "react";
import "./HeaderLower.css";
function HeaderLower() {
  return (
    <div className="header_lower">
      {/* City Furniture and Elite Rewards are proud supporters of the American Cancer Society. */}
      Ashley Furniture and Elite Rewards are proud supporters of <br/>Hope to Dream.
    </div>
  );
}
export default HeaderLower;
