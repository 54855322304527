import React, { useLayoutEffect } from "react";
import { useState, useEffect } from "react";
import "./style.css";
import Header from "./Header";
import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import Recaptcha from "react-recaptcha";
import { Helmet } from "react-helmet";
import LogService from "../services/log.service";
import Footer from "./Footer";

// import axios from "axios";

function Index(props) {
  // const TITLE = "City Furniture | Register";
  const navigate = useNavigate();
  const [website, setWebsite] = useState(window.location.href);

  // useLayoutEffect(() => {
  //   const destroy = () => {
  //     localStorage.clear();
  //   };
  //   destroy();
  // }, []);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "false");
    // props.history.push("/register/RegisterForm");
    navigate("/register");
    // <--- The page you want to redirect your user to.
  };

  if (localStorage.getItem("return_page") === "true") {
    return <Navigate to="/register" />;
  }

  localStorage.setItem("return_page", "false");
  localStorage.setItem("TestOurTech_thankyou", "false");

  return (
    <>
      {/* <Helmet>
        <title>{TITLE}</title>
      </Helmet> */}

      <div className="container-fluid">
        <Header />
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              <h2
                className="register_lower"
                style={{ textAlign: "center"}}
              >
                {/* City Furniture and Elite Rewards are proud supporters of the
                American Cancer Society. */}
                Ashley Furniture and Elite Rewards are proud supporters of Hope to Dream.
              </h2>
              <div className="row">
                <div className="col-md-12" id="text">
                  {/* <p>We Give $25, You Get $25. Would you be interested in giving 5-10 minutes of your time to improve your sleep and help support our fight to end heart disease? We are offering you a $25 reward
         card to do a sleep survey and CITY Furniture will make a $25 donation to the American Heart Association. Just a few moments of your time can help improve your sleep and help save a life.</p> */}
                  <p>
                    {" "}
                    {/* Would you be interested in giving 8-10 minutes of your time
                    to improve your sleep and be part of a movement to help end
                    breast cancer? You’ll get a $25 gift card when you complete
                    a mattress comfort test rest, and we’ll also give $25 to the
                    American Cancer Society. Just a few moments of your time can
                    help support breast cancer research, including access to
                    screenings and transportation to treatments. */}
                    Would you be interested in giving 15 minutes of your time to
                    improve your sleep? You will get a $20 Mastercard Reward
                    Card when you complete a mattress comfort test rest, and
                    we’ll also give a portion of the proceeds to Hope to Dream.
                    Just a few moments of your time can help the way you sleep.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit1"
                value="CONTINUE"
                className="sub-btn button"
              ></input>
            </form>
          </ul>
        </div>
      </div>

      {/* <Footer2 /> */}
      <Footer/>
    </>
  );
}
export default Index;
