import React from "react";
import { useEffect } from "react";
import "./LanguageTranslate.css";
const LanguageTranslate = () => {
  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages : 'en,es',
          autoDisplay: false
        },
        "google_translate_element"
      );
    };
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <>
      <div className="translation-box">
        <div id="google_translate_element"></div>
      </div>
    </>
  );
};
export default LanguageTranslate;
